import React, { KeyboardEvent } from 'react';
import { css, cx } from '@emotion/css';
import { observer } from 'mobx-react';
import { observable, action, makeObservable } from 'mobx';
import isEqual from 'lodash/isEqual';
import chooseDataIcon from "@extensions/utils/chooseDataIcon";

export interface FilterItem {
  key: string;
  doc_count: string;
}

export interface ValueType {
  [option_key: string]: boolean;
}
export interface ICheckboxGridProps {
  data: FilterItem[];
  value: ValueType;
  handleChange: (event: { target: { value: string } }) => void;
  checkboxColumnHeader: string | React.ReactNode;
  // The id of the element which labels this checkbox grid
  labeledBy: string;
  className?: string;
  maxLabelWidth?: string;
}

interface GridElement {
  optionKey: string;
  column: 'checkbox' | 'count';
}

const scrollContainerStyle = css`
  max-height: 360px;
  overflow-y: auto;
  overflow-x: hidden;
`;

const gridStyle = css`
  display: flex;
  flex-direction: column;
  align-items: stretch;
`;
const rowStyle = css`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  font-family: "Open Sans", sans-serif;
  line-height: 1.43;
`;
const headerRowStyle = css`
  height: 0;
  padding: 0;
  margin: 0;
  color: rgba(0, 0, 0, 0);
`;

const labelStyle = css`
  display: flex;
  align-items: baseline;
  padding-left: 0.25rem;
`;
const countStyle = css`
  min-width: max-content;
  padding-right: 0.25rem;
  font-size: 80%;
  color: #999;
`;
const checkBoxStyle = css`
  margin: 0;
`

@observer
export default class CheckboxGrid extends React.Component<ICheckboxGridProps> {
  rootElementRef: React.RefObject<HTMLDivElement>;
  renderIcon: boolean;
  constructor(props) {
    super(props);
    makeObservable(this);
    this.rootElementRef = React.createRef();
    this.renderIcon = false;
  }
  @observable
  focusedElement: GridElement | null = null;

  componentDidMount() {
    this.resetFocusedElement();
  }
  componentDidUpdate(prevProps) {
    if (!isEqual(prevProps.data, this.props.data)) {
      this.resetFocusedElement();
    }
  }

  @action
  setFocusedElement = (newFocusedElement: GridElement) => {
    return (this.focusedElement = newFocusedElement);
  };

  @action
  resetFocusedElement = () => {
    if (this.props.data.length > 0) {
      const firstOption = this.props.data[0];
      this.focusedElement = {
        optionKey: firstOption.key,
        column: 'checkbox',
      };
    } else {
      this.focusedElement = null;
    }
  };

  getTabIndex = ({
    element,
    focusedElement,
  }: {
    element: GridElement;
    focusedElement: GridElement | null;
  }) => {
    return isEqual(element, focusedElement) ? 0 : -1;
  };

  focusOnElement = (gridElement: GridElement) => {
    const rootElement = this.rootElementRef.current;
    if (rootElement) {
      let elementSelector = `[role='row'][data-key='${gridElement.optionKey}'] `;
      if (gridElement.column === 'checkbox') {
        elementSelector += 'input';
      }
      if (gridElement.column === 'count') {
        elementSelector += "[role='gridcell']:nth-of-type(2)";
      }
      const elementToFocus = rootElement.querySelector(elementSelector);
      if (elementToFocus) {
        (elementToFocus as HTMLElement & { focus: () => void }).focus();
      }
    }
  };

  @action
  handleKeyDown = ({
    e,
    element,
  }: {
    e: KeyboardEvent;
    element: GridElement;
  }) => {
    const { data } = this.props;
    const indexOfOption = data.findIndex(
      option => option.key === element.optionKey
    );
    const isFirstOption = indexOfOption === 0;
    const isLastOption = indexOfOption === data.length - 1;

    let newFocusedElement: GridElement | null = null;
    switch (e.key) {
      case 'ArrowRight':
        if (element.column === 'checkbox') {
          newFocusedElement = {
            optionKey: element.optionKey,
            column: 'count',
          };
        } else if (element.column === 'count' && !isLastOption) {
          newFocusedElement = {
            optionKey: data[indexOfOption + 1].key,
            column: 'checkbox',
          };
        }
        break;
      case 'ArrowLeft':
        if (element.column === 'count') {
          newFocusedElement = {
            optionKey: element.optionKey,
            column: 'checkbox',
          };
        } else if (element.column === 'checkbox' && !isFirstOption) {
          newFocusedElement = {
            optionKey: data[indexOfOption - 1].key,
            column: 'count',
          };
        }
        break;
      case 'ArrowDown':
        if (!isLastOption) {
          newFocusedElement = {
            optionKey: data[indexOfOption + 1].key,
            column: element.column,
          };
        }
        break;
      case 'ArrowUp':
        if (!isFirstOption) {
          newFocusedElement = {
            optionKey: data[indexOfOption - 1].key,
            column: element.column,
          };
        }
        break;
      case 'Home':
        newFocusedElement = {
          optionKey: element.optionKey,
          column: 'checkbox',
        };
        break;
      case 'End':
        newFocusedElement = {
          optionKey: element.optionKey,
          column: 'count',
        };
        break;
    }

    if (newFocusedElement) {
      e.preventDefault();
      this.setFocusedElement(newFocusedElement);
      this.focusOnElement(newFocusedElement);
    }
  };

  renderOptionKey = (
    checkboxColumnHeader: string | React.ReactNode,
    labeledBy: string,
    option: FilterItem |
    {
      key: string;
      doc_count: number;
    }
  ) => {
    let optionKey = '';
    let caseTitle = '';

    if(typeof checkboxColumnHeader !== 'string') {
      caseTitle = labeledBy;
    } else {
      caseTitle = checkboxColumnHeader;
    }

    switch (caseTitle) {
      case 'ProjectFilter-title':
      case 'project-filter-title':
      case 'Project':
        optionKey = option.key.toUpperCase();
        break;
      case 'Publication Type':
        optionKey = option.key.split(/(?=[A-Z])/).join(' ');
        break;
      case 'DataLevelFilter-title':
      case 'Data Level':
        this.renderIcon = true;
        optionKey = option.key
        break;
      default:
        optionKey = option.key;
        break;
    }
    return optionKey;
  };

  render() {
    const {
      data,
      value,
      handleChange,
      checkboxColumnHeader,
      labeledBy,
      className,
      maxLabelWidth,
    } = this.props;
    const optionStyle = css`
      display: inline-block;
      max-width: ${maxLabelWidth || '250px'};
      padding: 0 0.5rem;
      flex: 1;
    `;

    // These options have been selected by the user, but filtered out by another
    // facet or the search bar. To make things clearer for the user, they should
    // still be displayed, just with a count of 0
    const allFoundOptions = new Set(data.map(({ key }) => key));
    const filteredOutOptions = Object.entries(value)
      .filter(
        ([option, isSelected]) => isSelected && !allFoundOptions.has(option)
      )
      .map(([option]) => ({ key: option, doc_count: 0 }));
    return (
      <div className={cx(scrollContainerStyle, className)}>
        <div
          className={gridStyle}
          role="grid"
          ref={this.rootElementRef}
          aria-labelledby={labeledBy}
        >
          <div role="row" className={cx(rowStyle, headerRowStyle)}>
            <div role="columnheader">{checkboxColumnHeader}</div>
            <div role="columnheader">Count</div>
          </div>
          {[...filteredOutOptions, ...data].map(option => {
            const checkboxColumnElement: GridElement = {
              optionKey: option.key,
              column: 'checkbox',
            };
            const countColumnElement: GridElement = {
              optionKey: option.key,
              column: 'count',
            };
            return (
              <div
                role="row"
                className={rowStyle}
                data-key={option.key}
                key={option.key}
              >
                <div role="gridcell">
                  <label className={labelStyle}>
                    <input
                      type="checkbox"
                      className={checkBoxStyle}
                      checked={!!(value && value[option.key])}
                      value={option.key}
                      onChange={(...args) => {
                        this.setFocusedElement(checkboxColumnElement);
                        handleChange(...args);
                      }}
                      tabIndex={this.getTabIndex({
                        element: checkboxColumnElement,
                        focusedElement: this.focusedElement,
                      })}
                      onKeyDown={e =>
                        this.handleKeyDown({
                          e,
                          element: checkboxColumnElement,
                        })
                      }
                    />
                    <span className={optionStyle}>
                      {this.renderIcon && chooseDataIcon(option.key)}
                      {' '}
                      {this.renderOptionKey(checkboxColumnHeader, labeledBy, option)} 
                    </span>
                  </label>
                </div>
                <div
                  role="gridcell"
                  className={countStyle}
                  tabIndex={this.getTabIndex({
                    element: countColumnElement,
                    focusedElement: this.focusedElement,
                  })}
                  onKeyDown={e =>
                    this.handleKeyDown({ e, element: countColumnElement })
                  }
                >
                  {option.doc_count.toLocaleString()}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  }
}
