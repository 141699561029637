import styled from '@emotion/styled';
import { useEffect, useState } from 'react';
import { inject, observer } from 'mobx-react';
import RefreshIcon from '@mui/icons-material/Refresh';
import {
  Button,
  Grid,
  Typography,
  Alert,
  AlertTitle,
  TextField,
  IconButton,
} from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';

import { IUploadService } from '@extensions/services/IUploadService';
import { useHashStateKey } from '@extensions/hooks/useUrl';

import Client from '@extensions/components/data/uploaders/Client';
import { IStepProps, Step } from '@extensions/components/data/uploaders/Step';

const Filter = styled(TextField)`
  & input {
    font-family: monospace;
  }
`;

interface IStepManageProps extends IStepProps {
  uploadService?: IUploadService;
}

const StepManage = inject('uploadService')(
  observer(({ uploadService, ...stepProps }: IStepManageProps) => {
    const allClients = uploadService ? uploadService.clients : [];

    const pendingClients = uploadService
      ? uploadService.clients.filter((client) => !client.approved)
      : [];

    const approvedClients = uploadService
      ? uploadService.clients.filter((client) => client.approved)
      : [];

    const datasetsAssigned = allClients
      .map((client) => client.datasets)
      .reduce((flattened, clients) => [...flattened, ...clients], []);

    pendingClients.sort((a, b) => a.name.localeCompare(b.name));
    approvedClients.sort((a, b) => a.name.localeCompare(b.name));

    const [filter, setFilter] = useHashStateKey('filter', '');

    const regex = new RegExp(filter.replace(/\*/g, '.*'), 'i');

    const datasetsUnassigned =
      uploadService && uploadService.currentProjectConfig
        ? uploadService.currentProjectConfig.datasets
            .filter(
              (datasetName) =>
                datasetsAssigned.indexOf(datasetName) < 0 &&
                (filter === '' || regex.test(datasetName))
            )
            .sort()
        : [];

    const clientFilter = (client) => {
      return (
        filter === '' ||
        regex.test(client.name) ||
        regex.test(client.alias) ||
        regex.test(client.id) ||
        (client.state && client.state.ip.some((ip) => regex.test(ip))) ||
        client.datasets.some((d) => regex.test(d))
      );
    };

    useEffect(() => {
      let cancel = setInterval(
        () => uploadService && uploadService.refreshClients(),
        60 * 1000
      );
      return () => clearInterval(cancel);
    }, [uploadService]);

    return (
      <Step
        {...stepProps}
        title="Manage Clients"
        body={
          <Grid container direction="column" spacing={1}>
            <Grid item sx={{ width: '100%' }}>
              <Grid container spacing={1} direction="column">
                <Grid item>
                  <Typography sx={{ color: 'rgba(0, 0, 0, 0.65) !important' }}>
                    <em>Running Executable</em>&nbsp;
                    <code>+</code>&nbsp;
                    <em>Host Computer</em>&nbsp;
                    <code>=</code>&nbsp;
                    <strong>Client</strong>
                  </Typography>
                </Grid>
                <Grid item>
                  <Filter
                    variant="standard"
                    fullWidth
                    placeholder="Filter List ..."
                    value={filter}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      setFilter(event.target.value);
                    }}
                    InputProps={{
                      endAdornment: (
                        <IconButton size="small" onClick={() => setFilter('')}>
                          <ClearIcon fontSize="small" />
                        </IconButton>
                      ),
                    }}
                  />
                </Grid>
                <Grid item>
                  <Button
                    variant="contained"
                    sx={{
                      backgroundColor: 'rgb(224, 224, 224) !important',
                      color: '#000  !important',
                    }}
                    disableElevation
                    fullWidth
                    onClick={() =>
                      uploadService && uploadService.refreshClients()
                    }
                  >
                    <RefreshIcon />
                    &nbsp;Refresh Clients
                  </Button>
                </Grid>
                {pendingClients.filter(clientFilter).map((client) => (
                  <Grid item key={client.id} sx={{ width: '100%' }}>
                    <Client client={client} assignableDatasets={[]} />
                  </Grid>
                ))}
                {filter === '' && pendingClients.length === 0 && (
                  <Grid item>
                    <Alert severity="info">
                      <AlertTitle>No Clients Pending Approval</AlertTitle>
                      If this is unexpected, check for any errors in the
                      executable output.
                    </Alert>
                  </Grid>
                )}
              </Grid>
            </Grid>
            <Grid item sx={{ width: '100%' }}>
              <Grid container spacing={1} direction="column">
                {datasetsUnassigned.length > 0 &&
                  approvedClients.length > 0 && (
                    <Grid item>
                      <Alert severity="warning">
                        <AlertTitle>
                          {datasetsUnassigned.length} Unassigned Datasets
                        </AlertTitle>
                        <ul>
                          {datasetsUnassigned.map((datasetName) => (
                            <li key={datasetName}>
                              <code>{datasetName.split('/').pop()}</code>
                            </li>
                          ))}
                        </ul>
                      </Alert>
                    </Grid>
                  )}
                {filter === '' &&
                  datasetsUnassigned.length === 0 &&
                  approvedClients.length > 0 && (
                    <Grid item>
                      <Alert severity="info">
                        <AlertTitle>No Unassigned Datasets</AlertTitle>
                      </Alert>
                    </Grid>
                  )}
                {approvedClients.filter(clientFilter).map((client) => (
                  <Grid item key={client.id} sx={{ width: '100%' }}>
                    <Client
                      client={client}
                      assignableDatasets={datasetsUnassigned}
                    />
                  </Grid>
                ))}
                {filter === '' && approvedClients.length === 0 && (
                  <Grid item>
                    <Alert severity="info">
                      <AlertTitle>No Approved / Enabled Clients</AlertTitle>
                    </Alert>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Grid>
        }
      />
    );
  })
);

export default StepManage;
