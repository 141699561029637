import React from 'react';
import { action, observable } from 'mobx';
import { inject, observer } from 'mobx-react';
import { Controller, UseFormReturn } from 'react-hook-form';

import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';

import { PublicationFormData } from '@extensions/models/PublicationFormData';
import { PublicationFormMethod } from '@extensions/components/publications/ChooseMethod';

export interface IPublicationDateFieldProps {
  className?: string;
  method: PublicationFormMethod;
  control: UseFormReturn<PublicationFormData>['control'];
}

@observer
export class PublicationDateField extends React.Component<
  IPublicationDateFieldProps
> {

  @observable
  inputDate: Date | null = null;
  @action
  setInputDate = (inputDate) => {
    this.inputDate = inputDate;
  }

  render() {
    const { control, method } = this.props;
    return (
      <Controller
        name="publicationDate"
        control={control}
        render={({ field, fieldState }) => {
          const setDate = (newDate) => {
            field.onChange(newDate);
            this.setInputDate(newDate);
          };
          return (
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                format="yyyy-MM-dd"
                label="Publication Date"
                value={method === PublicationFormMethod.MANUAL ? this.inputDate : field.value}
                onChange={setDate}
                maxDate={new Date()}
                sx={{
                  width: "100%",
                  '& .MuiInputBase-input': {
                    padding: '14px'
                  },
                  "& .MuiOutlinedInput-root": {
                    "> fieldset": { borderColor: "#C7C8CD" },
                  },
                }}
              />
            </LocalizationProvider>
          );
        }}
      />
    );
  }
}

export default inject((store: any) => ({
  // EXAMPLE => metricsService: store.metricsService
}))(PublicationDateField);
