import React, { useState, useEffect } from 'react';
import { ReactiveComponent } from '@appbaseio/reactivesearch';
import { react } from '@appbaseio/reactivesearch/lib/types';
import get from 'lodash/get';
import moment from 'moment';
import FilterTitle from '@extensions/components/search-core/FilterTitle';
import DatePicker from '@dapclient/components/core/date-picker';
import { DateRange } from '@dapclient/components/core/date-picker/DatePicker';
import CenteredCircularProgress from '@extensions/components/core/CenteredCircularProgress';

const DATE_FORMAT = 'YYYY-MM-DD';
export const DATE_RANGE_REACTIVE_ID = 'DateRangeFilter';

export default class DateRangeFilter extends React.Component<{
  startDateField: string;
  endDateField: string;
  react?: react;
}> {
  render = () => {
    const { startDateField, endDateField } = this.props;
    return (
      <>
        <ReactiveComponent
          componentId={DATE_RANGE_REACTIVE_ID}
          defaultQuery={() => ({
            size: 0,
            aggs: {
              maxDate: { max: { field: endDateField } },
              minDate: { min: { field: startDateField } },
            },
          })}
          react={this.props.react}
          showFilter={true}
          filterLabel="Dates"
          URLParams={true}
          render={({ aggregations, setQuery, value, loading, error }) => {
            const rawMaxDate = get(aggregations, 'maxDate.value', null);
            const rawMinDate = get(aggregations, 'minDate.value', null);
            const hidden = loading || error || !rawMaxDate || !rawMinDate;
            return (
              <div className="data-filter" style={ hidden ? {'display': 'none'} : {} }>
                <FilterTitle hidden={hidden}>Date Range</FilterTitle>
                <AttachedDatePicker
                  aggregations={aggregations}
                  setQuery={setQuery}
                  value={value}
                  startDateField={startDateField}
                  endDateField={endDateField}
                  hidden={hidden}
                />
                <p style={{ fontSize: '0.75rem', fontStyle: 'italic' }}>
                  Date format: YYYY-MM-DD          
                </p>
              </div>
            );
          }}
        />
      </>
    );
  };
}

function getQueryForRange({
  range,
  startDateField,
  endDateField,
}: {
  range: DateRange;
  startDateField: string;
  endDateField: string;
}): Record<string, any> {
  if (range.endDate === null || range.startDate === null) {
    return {
      query: {
        match_all: {},
      },
      value: null,
    };
  } else {
    return {
        query: {
          bool: {
            must: [
              {
                exists: {
                  field: startDateField,
                },
              },
              {
                bool: {
                  must_not: [
                    {
                      range: {
                        [startDateField]: {
                          gt: range.endDate.endOf('day').valueOf(),
                          format: 'epoch_millis',
                        },
                      },
                    },
                    {
                      range: {
                        [endDateField]: {
                          lt: range.startDate.startOf('day').valueOf(),
                          format: 'epoch_millis',
                        },
                      },
                    },
                  ],
                },
              },
            ],
          },
        },
      value: toValue(range),
    };
  }
}

function fromValue(value: string): DateRange {
  if (value === null) {
    return {
      startDate: null,
      endDate: null,
    };
  }
  const [startStr, endStr] = value.split(' to ');
  return {
    startDate: moment(startStr, DATE_FORMAT),
    endDate: moment(endStr, DATE_FORMAT),
  };
}

function toValue(range: DateRange | null): string | null {
  if (range === null || range.startDate === null || range.endDate === null) {
    return null;
  }
  return `${range.startDate.format(DATE_FORMAT)} to ${range.endDate.format(
    DATE_FORMAT
  )}`;
}


const AttachedDatePicker = ({ aggregations, setQuery, value, hidden, startDateField, endDateField }) => {
  const [dateRange, setDateRange] = useState<DateRange>({
    startDate: null,
    endDate: null,
  });

  useEffect(() => {
    const rangeInUrl = fromValue(value);
    if (
      rangeInUrl.endDate !== dateRange.endDate ||
      rangeInUrl.startDate !== dateRange.startDate
    ) {
      setDateRange(rangeInUrl);
      setQuery(getQueryForRange({ range: rangeInUrl, startDateField, endDateField }));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  const ready = aggregations && aggregations.maxDate && aggregations.minDate;

  if (!ready) {
    return <CenteredCircularProgress />;
  }

  const minDate = aggregations.minDate.value;
  const maxDate = aggregations.maxDate.value;
  
  return !hidden ? (
    <DatePicker 
      startDate={dateRange.startDate}
      endDate={dateRange.endDate}
      startDateId="date-search-start"
      endDateId="date-search-end"
      oldestAllowed={minDate && moment(minDate)}
      newestAllowed={maxDate && moment(maxDate)}
      onDatesChange={(newDateRange) => {
        const { startDate: newStartDate, endDate: newEndDate } = newDateRange;
        setDateRange(newDateRange);
        if (newStartDate && newEndDate) {
          setQuery({
            query: {
              bool: {
                must: [
                  {
                    range: {
                      [startDateField]: {
                        lte: newEndDate.endOf('day').valueOf(),
                        format: 'epoch_millis',
                      },
                    },
                  },
                  {
                    range: {
                      [endDateField]: {
                        gte: newStartDate.startOf('day').valueOf(),
                        format: 'epoch_millis',
                      },
                    },
                  },
                ],
              },
            },
            value: toValue(newDateRange),
          });
        }
      }}
    />
  ) : null
}
