import React from 'react';

export interface IGlobalErrorBoundaryProps {}

export default class GlobalErrorBoundary extends React.Component<
  IGlobalErrorBoundaryProps
> {

  decodeEmail = (decrypt: string) => {
    return atob(decrypt);
  }

  render() {

    const encodedEmail = "ZGFwdGVhbUBwbm5sLmdvdg==";
    const email = this.decodeEmail(encodedEmail);

    return (
      <div
        style={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          maxWidth: '30rem',
        }}
      >
        <h1 style={{ marginBottom: '1rem' }}>Whoops, something went wrong!</h1>
        <p>
          Our team is working to address the issue. If you'd like to help,{' '}
          <a href={`mailto:${email}`}>
            send us an email
          </a>{' '}
          explaining what happened.
        </p>
      </div>
    );
  }
}
