import StarBorderRoundedIcon from '@mui/icons-material/StarBorderRounded';
import StarRateRoundedIcon from '@mui/icons-material/StarRateRounded';
import CheckIcon from '@mui/icons-material/Check';
import MergeTypeIcon from '@mui/icons-material/MergeType';


export default function renderDataLevelKeys(optionKey: string): React.ReactElement | null {
    let dataIcon: React.ReactElement | null = null;

    switch (optionKey) {
        case "Raw Data (00)":
            dataIcon = <StarBorderRoundedIcon style={{color: '#4570E1', verticalAlign: 'top'}} />
            break;
        case "Processed Data (a0)":
            dataIcon = <StarRateRoundedIcon style={{color: '#4570E1', verticalAlign: 'top'}} />
            break;
        case "Processed Data Reformatted (a1)":
            dataIcon = <StarRateRoundedIcon style={{color: '#51A800', verticalAlign: 'top'}} />
            break;    
        case "Reviewed Data (b0)":
            dataIcon = <CheckIcon style={{color: '#4570E1', verticalAlign: 'top'}} />
            break;
        case "Reviewed Data Reformatted (b1)":
            dataIcon = <CheckIcon style={{color: '#51A800', verticalAlign: 'top'}} />
            break;            
        case "Derived Data (c0)":
            dataIcon = <MergeTypeIcon style={{color: '#4570E1', verticalAlign: 'top'}} />
            break;
        case "Derived Data Reformatted (c1)":
            dataIcon = <MergeTypeIcon style={{color: '#51A800', verticalAlign: 'top'}} />
            break;
        case "Images (i0)":
            dataIcon = null;
            break;
    }

    return dataIcon;
}