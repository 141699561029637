import React from 'react';
import { MultiList } from '@dapclient/components/search-core';
import { react } from '@appbaseio/reactivesearch/lib/types';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import DatasetIconLegend from '@extensions/components/dataset-search/DatasetIconLegend';

export const DATA_LEVEL_REACTIVE_ID = 'DataLevelFilter';

interface DataLevelFilterState {
  dialogOpen: boolean;
}

export default class DataLevelFilter extends React.Component<
  { react?: react; }, 
  DataLevelFilterState
> {
  constructor(props) {
    super(props);
    this.state = { dialogOpen: false };
  }

  handleDialogOpen = () => {
    this.setState({ dialogOpen: true });
  }

  handleDialogClose = () => {
    this.setState({ dialogOpen: false });
  }

  render = () => {
    const { dialogOpen } = this.state;
    
    return (
      <>
        <MultiList
          className="data-filter"
          componentId={DATA_LEVEL_REACTIVE_ID}
          dataField="dataLevel.keyword"
          title={
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              Data Level
              <InfoOutlinedIcon 
                onClick={() => this.handleDialogOpen()} 
                style={{ cursor: 'pointer' }}
              />
            </div>
          }
          filterLabel="Level"
          showSearch={false}
          react={this.props.react}
          URLParams={true}
        />

        <DatasetIconLegend open={dialogOpen} onClose={this.handleDialogClose} />
      </>
    );
  };
}
