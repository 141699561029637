import React from 'react';
import { inject, observer } from 'mobx-react';

import isEqual from 'lodash/isEqual';
import { styled } from '@mui/material/styles';

import * as L from 'leaflet';
import 'leaflet.markercluster';
import 'leaflet.markercluster/dist/MarkerCluster.css';
import marker from 'leaflet/dist/images/marker-icon.png';
import shadow from 'leaflet/dist/images/marker-shadow.png';
import { GestureHandling } from 'leaflet-gesture-handling';
import 'leaflet.markercluster/dist/leaflet.markercluster.js';
import 'leaflet.markercluster/dist/MarkerCluster.Default.css';
import 'leaflet-gesture-handling/dist/leaflet-gesture-handling.css';

import theme from '@extensions/services/Theme';
import ILayoutService from '@extensions/services/ILayoutService';

L.Map.addInitHook('addHandler', 'gestureHandling', GestureHandling);

declare module 'leaflet' {
  interface MapOptions {
    gestureHandling: boolean;
  }
}

export interface Point {
  lat: number;
  lon: number;
}
export interface Dataset {
  name: string;
  longName: string;
}
export interface DatasetsPoint extends Point {
  datasets: Dataset[];
}

export interface SearchArea {
  northWestCorner: Point;
  southEastCorner: Point;
}

export interface IDatasetPointsMapProps {
  points: DatasetsPoint[];
  handleSearchThisArea: (area: SearchArea) => void;
  currentSearchArea: SearchArea | null;
  visible: boolean;
  className?: string;
  layoutService?: ILayoutService;
}

const StyledRootDiv = styled('div')(({
  height: '300px',
  '& .leaflet-div-icon': {
    border: 'none',
    background: 'transparent',
  },
}));

const WatermarkDiv = styled('div')(({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  color: 'rgba(0, 0, 0, 0.5)',
  fontSize: '1.25rem',
  pointerEvents: 'none',
  zIndex: 9000,
}))

interface NumberedIconOptions extends L.IconOptions {
  number?: number;
}

class NumberedIcon extends L.Icon {

  num: number

  constructor(options: NumberedIconOptions) {
    super(options);
    this.num = options.number || 1;
  }

  createIcon() {
    const container = document.createElement('div');
    const number = document.createElement('div');
    const icon = super.createIcon();
    number.style.position = 'absolute';
    number.style.paddingTop = '1px';
    number.style.width = '15px';
    number.style.height = '15px';
    number.style.borderRadius = '8px';
    number.style.fontSize = '9px';
    number.style.left = '-8px';
    number.style.top = '-36px';
    number.style.textAlign = 'center';
    number.style.backgroundColor = '#fff';
    number.innerHTML = `${this.num || ''}`;
    container.style.position = 'relative';
    container.appendChild(icon);
    container.appendChild(number);
    return container;
  }
}

class DatasetMarker extends L.Marker {
  data: DatasetsPoint;

  constructor(latLng: L.LatLngExpression, data: DatasetsPoint, options?: L.MarkerOptions) {
    options = options || {}
    options.icon = new NumberedIcon({
      iconUrl: marker,
      shadowUrl: shadow,
      iconSize: new L.Point(25, 41),
      iconAnchor: new L.Point(13, 41),
      popupAnchor: new L.Point(0, -33),
      number: data.datasets.length
    });
    super(latLng, options);
    this.data = data;
  }
}

@inject('layoutService')
@observer
class DatasetPointsMap extends React.Component<IDatasetPointsMapProps> {
  leafletMap: L.Map | null = null;
  mapWrapper: React.RefObject<HTMLDivElement>;
  datasetPointsClusterGroup: L.MarkerClusterGroup | null = null;
  searchButton: HTMLButtonElement | null = null;
  hack: boolean | undefined;
  fullWidth: boolean = false;
  triggeredManually: boolean = false;
  currentVisibleCount: number = 0;

  constructor(props) {
    super(props);
    this.mapWrapper = React.createRef();
    this.fullWidth = props.layoutService && props.layoutService.fullWidth;
  }

  bestMapBounds = (): L.LatLngBounds => {
    const { points, currentSearchArea } = this.props;
    if (currentSearchArea) {
      return L.latLngBounds(
        L.latLng(
          currentSearchArea.southEastCorner.lat,
          currentSearchArea.southEastCorner.lon
        ),
        L.latLng(
          currentSearchArea.northWestCorner.lat,
          currentSearchArea.northWestCorner.lon
        )
      );
    }
    if (points.length === 0) {
      return L.latLngBounds(
        new L.LatLng(48.645458, -124.198338),
        new L.LatLng(26.136522, -72.43505)
      );
    }
    return L.latLngBounds(
      this.props.points.map((point) => [point.lat, point.lon])
    );
  };

  zoomToFit = (): void => {
    // Zoom the map in to match the available data
    if (this.leafletMap) {
      let fitBoundsOptions: L.FitBoundsOptions = {};
      if (!this.props.currentSearchArea) {
        fitBoundsOptions.maxZoom = 10;
      }
      this.triggeredManually = true;
      this.leafletMap.fitBounds(this.bestMapBounds(), fitBoundsOptions);
    }
  };

  updateClusters = () => {
    if (this.datasetPointsClusterGroup) {
      this.datasetPointsClusterGroup.clearLayers();
      const popupCss = `
        display: flex;
        flex-direction: column;
        max-height: 200px;
        overflow: auto;
      `;
      const markers = this.props.points.map(
        (point) => new DatasetMarker([point.lat, point.lon], point).bindPopup(
          `<div style="${popupCss}">` +
          point.datasets.sort(
            (a, b) => a.name.localeCompare(b.name)
          ).map(d => `
            <div style="margin-bottom:0.5rem">
              <a href="/ds/${d.name.replace(/<\/?mark>/g, '')}">
                <code>${d.name}</code>
              </a>
              <br/>
              ${d.longName}
            </div>`
          ).join('') + `
            <div style="text-align:center">
              <hr/>
              <code>${point.lat}, ${point.lon}</code>
            </div>
          </div>`
        )
      );
      this.datasetPointsClusterGroup.addLayers(markers);
    }
  };

  truncateLatLng = (latlng: L.LatLng): L.LatLng => {
    let lat = latlng.lat;
    if (lat < -90) {
      lat = -90;
    } else if (lat > 90) {
      lat = 90;
    }

    let lng = latlng.lng;
    if (lng < -180) {
      lng = -180;
    } else if (lng > 180) {
      lng = 180;
    }
    return new L.LatLng(lat, lng);
  };

  countVisiblePoints = () => {
    if (this.leafletMap) {
      const { points } = this.props;
      if (points.length) {
        const bounds = this.leafletMap.getBounds();
        return points.filter(
          p => bounds.contains([p.lat, p.lon])
        ).length;
      }
    }
    return 0;
  };

  handleZoomPanStart = () => {
    this.currentVisibleCount = this.countVisiblePoints();
  };

  handleZoomPanSettle = () => {
    if (this.triggeredManually) {
      this.triggeredManually = false;
      return;
    }
    if (this.currentVisibleCount > 0 && this.currentVisibleCount !== this.countVisiblePoints()) {
      this.handleSearchButtonClick();
    }
  };

  handleSearchButtonClick = () => {
    const currentBoundingBox: L.LatLngBounds = this.leafletMap!.getBounds();
    const northWestCorner: L.LatLng = this.truncateLatLng(
      currentBoundingBox.getNorthWest()
    );
    const southEastCorner: L.LatLng = this.truncateLatLng(
      currentBoundingBox.getSouthEast()
    );
    this.props.handleSearchThisArea({
      northWestCorner: {
        lat: northWestCorner.lat,
        lon: northWestCorner.lng,
      },
      southEastCorner: {
        lat: southEastCorner.lat,
        lon: southEastCorner.lng,
      },
    });
  };

  onSearchButtonAdd = (map) => {
    this.searchButton = L.DomUtil.create(
      'button',
      'searchButton'
    ) as HTMLButtonElement;
    this.searchButton.textContent = 'Search This Area';
    this.searchButton.setAttribute('style',
      `background-color: ${theme.palette.secondary.main};
      border-color: ${theme.palette.secondary.main};
      color: ${theme.palette.common.white};
      font-size: 14px;
      padding: 4px 16px;
      border: none;
      cursor: pointer;
      height: 32px;
      `
    );
    L.DomEvent.on(this.searchButton, 'click', this.handleSearchButtonClick);
    return this.searchButton;
  };

  onSearchButtonRemove = (map) => {
    if (this.searchButton) {
      L.DomEvent.off(this.searchButton, 'click', this.handleSearchButtonClick);
    }
  };

  addSearchButton = () => {
    if (this.leafletMap) {
      const SearchThisArea = L.Control.extend({
        onAdd: this.onSearchButtonAdd,
        onRemove: this.onSearchButtonRemove,
      });
      new SearchThisArea({ position: 'topright' }).addTo(this.leafletMap);
    }
  };

  showMap = () => {
    const wrapper = this.mapWrapper.current;
    if (wrapper) {
      wrapper.style.display = 'block';
    }
    if (this.leafletMap) {
      this.leafletMap.invalidateSize();
    }
  };

  hideMap = () => {
    const wrapper = this.mapWrapper.current;
    if (wrapper) {
      wrapper.style.display = 'none';
    }
  };

  renderWatermark = () => {
    const currentPoints = this.countVisiblePoints()
    if (currentPoints === 0) {
      return <WatermarkDiv>No Geographic Data Available</WatermarkDiv>
    }
    return null;
  }

  componentDidMount = () => {
    if (this.mapWrapper.current) {
      this.leafletMap = L.map(this.mapWrapper.current, {
        gestureHandling: true,
      });
      this.leafletMap.addEventListener('movestart', this.handleZoomPanStart);
      this.leafletMap.addEventListener('moveend', this.handleZoomPanSettle);
      L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
        attribution:
          "&copy; <a href='http://osm.org/copyright'>OpenStreetMap</a> contributors",
      }).addTo(this.leafletMap);
      this.datasetPointsClusterGroup = L.markerClusterGroup({
        maxClusterRadius: 50,
        spiderLegPolylineOptions: {
          weight: 2,
          color: 'black',
          opacity: 0.5,
        },
        iconCreateFunction: cluster => {
          const maxR = 30;
          const minR = 15;
          const maxC = 100;
          let count = 0;
          for (const m of cluster.getAllChildMarkers()) {
            count += (m as DatasetMarker).data.datasets.length;
          }
          const r = Math.min(minR + ((count / maxC) * (maxR - minR)), maxR);
          const outerCss = `
            margin-left: -${r - 5}px;
            margin-top: -${r - 5}px;
            background-color: rgba(181, 225, 140, 0.6);
            background-clip: padding-box;
            border-radius: ${r}px;
            width: ${r * 2}px;
            height: ${r * 2}px;
            padding: 5px;
          `;
          const innerCss = `
            background-color: rgba(110, 204, 57, 0.6);
            border-radius: ${r}px;
            width: ${(r * 2) - 10}px;
            height: ${(r * 2) - 10}px;
            display: flex;
            align-items: center;
            justify-content: center;
          `;
          return L.divIcon({
            html: `
              <div style="${outerCss}">
                <div style="${innerCss}">
                  <span style="">${count}</span>
                </div>
              </div>
            `,
          });
        },
      });
      this.updateClusters();
      this.leafletMap.addLayer(this.datasetPointsClusterGroup);
      this.addSearchButton();
      this.zoomToFit();
    }
  };

  componentDidUpdate = (prevProps: IDatasetPointsMapProps) => {
    const resized = (
      this.props.layoutService &&
      this.props.layoutService.fullWidth !== this.fullWidth
    );
    const dataChanged = !isEqual(
      prevProps.points, this.props.points
    );
    if (dataChanged) {
      this.updateClusters();
    }
    const searchAreaChanged = (
      !isEqual(prevProps.currentSearchArea, this.props.currentSearchArea) &&
      this.props.currentSearchArea === null
    );
    if (!isEqual(prevProps.visible, this.props.visible)) {
      if (this.props.visible) {
        this.showMap();
      } else {
        this.hideMap();
      }
    }
    if (resized && this.leafletMap) {
      this.fullWidth = this.props.layoutService?.fullWidth ?? false;
      this.leafletMap.invalidateSize();
    }
    if (resized || dataChanged || searchAreaChanged) {
      this.zoomToFit();
    }
  };

  render() {
    const { layoutService, } = this.props;

    // This is necessary for the component to react to fullWidth changes that
    // then get handled in componentDidUpdate()
    this.hack = layoutService?.fullWidth;

    return (
      <StyledRootDiv
        ref={this.mapWrapper}
        aria-hidden
      >
        {this.renderWatermark()}
      </StyledRootDiv>
    );
  }
}
export default DatasetPointsMap;
