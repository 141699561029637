import Dataset, {
  DatasetStats,
  DatasetRawStats,
} from '@extensions/models/Dataset';
import FileOrder from '@extensions/models/FileOrder';
import FileOrderGroup from '@extensions/models/FileOrderGroup';
import Project from '@extensions/models/Project';
import Quality from '@extensions/models/Quality';
import { ICachingService } from '@extensions/services/ICachingService';
import { Status, INotificationService } from '@extensions/services/INotificationService';
import {
  ABILITIES,
  ISecurityService,
} from '@extensions/services/ISecurityService';
import config from '@extensions/utils/ConfigUtil';
import DapApiAgent from '@extensions/utils/DapApiAgent';
import LambdaApiAgent from '@extensions/utils/LambdaApiAgent';
import DatasetMetrics from '@extensions/models/DatasetMetrics';
import memoize from 'lodash/memoize';
import moment, { Moment } from 'moment';
import sortBy from 'lodash/sortBy';
import reduce from 'lodash/reduce';
import { runInAction } from 'mobx';
import { filter, map } from 'lodash';

const LAMBDA_API_URL = config.getConfig().lambdaApi;
const NOTIFICATION_ID = 'QUALITY_JSON_FILE';

interface ProjectDatasets {
  project: Project;
  datasets: any[];
}

export default class CachingService implements ICachingService {
  getDataset: (datasetName: string) => Promise<Dataset>;
  getProject: (projectName: string) => Promise<Project>;
  getProjectLite: (projectName: string) => Promise<Project>;
  getStats: (filter: object) => Promise<DatasetStats>;
  getRawStats: (filter: object) => Promise<DatasetRawStats[]>;
  getOrder: (groupId: string) => Promise<FileOrderGroup>;
  getUploaderProjects: () => Promise<string[]>;
  getCodeHubIntro: () => Promise<string>;
  getStaticPage: (path: string) => Promise<string>;
  getSiteDoi: () => Promise<string>;
  private notificationService: INotificationService;
  private securityService: ISecurityService;

  constructor(
    notificationService: INotificationService,
    securityService: ISecurityService
  ) {
    this.notificationService = notificationService;
    this.securityService = securityService;
    this.getDataset = memoize(this._getDataset);
    this.getProject = memoize(this._getProject);
    // Need a lite version of getProject because it's very heavy with AJAX calls
    this.getProjectLite = memoize(this._getProjectLite);
    // Second arg specifies cache key (needed since otherwise the cache
    // uses object reference equality)
    this.getStats = memoize(this._getStats, (filter: object) =>
      JSON.stringify(filter)
    );
    this.getRawStats = memoize(this._getRawStats, (filter: object) =>
      JSON.stringify(filter)
    );
    this.getOrder = memoize(this._getOrder);
    this.getUploaderProjects = memoize(this._getUploaderProjects);
    this.getStaticPage = memoize(this._getStaticPage);
    this.getCodeHubIntro = memoize(this._getCodeHubIntro);
    this.getSiteDoi = memoize(this._getSiteDoi);
    securityService.addLoginListener(this.clearCache);
    securityService.addLogoutListener(this.clearCache);
  }

  private _getSiteDoi() {
    return DapApiAgent.agent
      .get('/api/info')
      .then((response) => response.body.siteDoi);
  }

  private _getStaticPage = (path: string) => {
    path = path.replace(/^\//, '');
    return DapApiAgent.agent
      .get(`${process.env.PUBLIC_URL}/static-pages/${path}.md`)
      .then((response) => response.text);
  };

  private _getCodeHubIntro = () => {
    return DapApiAgent.agent
      .get(`${process.env.PUBLIC_URL}/api/codehub/intro.md`)
      .then(r => r.text);
  };

  private _getUploaderProjects = () => {
    return DapApiAgent.agent
      .get('/uploaders')
      .then((response) => response.body);
  };

  private _getProjectLite = (projectName: string) => {
    return DapApiAgent.agent.get(`/api/projects/${projectName}`)
      .then(
        projectResp => {
          return new Project(
            { ...projectResp.body.meta },
            projectName
          );
        },
        error => {
          throw error;
        }
      );
  };

  private _getProject = (projectName: string) => {
    return Promise.allSettled([
      DapApiAgent.agent.get(`/api/projects/${projectName}`),
      DapApiAgent.agent.post(`/api/projects/_search`).send({
        query: {
          term: {
            'name.keyword': projectName,
          },
        },
        size: 1,
      }),
      LambdaApiAgent.agent.get(
        `${LAMBDA_API_URL}/order-summary-timeline?entity=${projectName}`
      ),
      DapApiAgent.agent.get(`/api/projects/${projectName}/datasets`),
    ])
      .then(
        ([
          projectResp,
          projectSearchResp,
          orderSummaryResp,
          datasetsResponse,
        ]) => {
          if (projectResp.status === 'rejected') {
            throw projectResp.reason;
          }

          let meta = {};
          if (projectSearchResp.status !== 'rejected') {
            const hits = projectSearchResp.value.body.hits.hits;
            if (hits) {
              meta = { ...hits[0]._source };
            }
          }

          const rawMetaData = projectResp.value.body;
          const project = new Project(
            { ...meta, ...rawMetaData.meta },
            rawMetaData.name
          );

          //order-summary api might reject when metadata is access controlled and auth'd
          //user is denied access.  If so, just don't set those values as they're only used
          //on the project page's stats
          if (orderSummaryResp.status !== 'rejected') {
            const sortedOrderStats = sortBy(orderSummaryResp.value.body, [
              'month',
            ]);
            project.orderByteCount = reduce(
              sortedOrderStats,
              (sum, stat) => sum + stat.size,
              0
            );
            project.orderFileCount = reduce(
              sortedOrderStats,
              (sum, stat) => sum + stat.file_count,
              0
            );
            project.orderCount = reduce(
              sortedOrderStats,
              (sum, stat) => sum + stat.order_count,
              0
            );
            project.downloadStats = sortedOrderStats;
          }
          let datasets: any[] = [];
          if (datasetsResponse.status !== 'rejected') {
            datasets = datasetsResponse.value.body;
          }
          return { project, datasets };
        }
      )
      .then((projectDatasets: ProjectDatasets) => {
        if (projectDatasets.datasets.length > 0 || projectDatasets.project) {
          LambdaApiAgent.agent.post(`${LAMBDA_API_URL}/searches`).send({
            filter: {
              Dataset: {
                begins: projectDatasets.project.identifier + '/',
              },
            },
            output: 'json',
            source: config.getConfig().reportTable,
          }).then(resp => {
            let count = 0;
            let bytes = 0;
            resp.body.forEach(d => {
              count += d.file_count;
              bytes += d.size;
            });
            runInAction(() => {
              projectDatasets.project.setDynamoFileCount(count);
              projectDatasets.project.setDynamoTotalFileSize(bytes);
            });
            return projectDatasets.project;
          });
        }
        return projectDatasets.project;
      });
  };

  private clearCache = () => {
    const memoizedFunctions = [
      this.getDataset,
      this.getProject,
      this.getStats,
      this.getRawStats,
      this.getOrder,
      this.getUploaderProjects,
      this.getCodeHubIntro,
      this.getStaticPage,
      this.getSiteDoi
    ] as unknown as Array<{ cache: { clear: () => void } }>;
    memoizedFunctions.forEach((func) => func.cache.clear());
  };

  // need to memoize in constructor
  private _getDataset = (datasetName: string) => {
    const requests = [
      DapApiAgent.agent.get(`/api/datasets/${datasetName}`),
      DapApiAgent.agent.post(`/api/datasets/_search`).send({
        query: {
          term: {
            'name.keyword': datasetName,
          },
        },
        _source: {
          includes: ['dapFileSummary'],
        },
        size: 1,
      }),
      DapApiAgent.agent.post(`/api/refs/_msearch`).send(
        JSON.stringify({preference: "SearchResult"}) + "\n" +
        JSON.stringify({
          query: {
            term: {
              'datasets.keyword': datasetName,
            },
          },
          sort: [
            {publicationDate: 'desc'},
          ],
        })
      ),
      LambdaApiAgent.agent.get(
        `${LAMBDA_API_URL}/order-summary?entity=${datasetName}`
      ),
    ];
    if (this.securityService.userIsLoggedIn) {
      requests.push(
        DapApiAgent.agent.get(`/api/can/admin/${datasetName}`),
        DapApiAgent.agent.get(`/api/can/upload/${datasetName}`),
        DapApiAgent.agent.get(`/api/can/download/${datasetName}`)
      );
    }

    return Promise.allSettled(requests).then(
      ([
        datasetResp,
        searchResp,
        pubSearchResp,
        orderSummaryResp,
        canAdminResponse,
        canUploadResponse,
        canDownloadResponse,
      ]) => {
        if (datasetResp.status === 'rejected') {
          throw datasetResp.reason;
        }

        const raw = datasetResp.value.body;

        if (searchResp.status !== 'rejected') {
          const hits = searchResp.value.body.hits.hits;
          if (hits.length) {
            // "spatial" property should come from database and not search
            // index since the former switches lat/lon to lon/lat
            raw.meta = { ...hits[0]._source, ...raw.meta };
          }
        }

        const dataset = new Dataset(raw);

        if (pubSearchResp.status !== 'rejected') {
          const pubResp = pubSearchResp.value.body.responses[0];
          if (pubResp.hits) {
            dataset.publications = pubResp.hits.hits.map(
              hit => hit._source
            );
          }
        }

        if (orderSummaryResp.status !== 'rejected') {
          const orderSummary = orderSummaryResp.value.body;
          dataset.orderByteCount = orderSummary.size;
          dataset.orderFileCount = orderSummary.file_count;
          dataset.orderCount = orderSummary.order_count;
        }

        const abilities: string[] = [];
        if (this.securityService.userIsLoggedIn) {
          if (canAdminResponse.status === 'rejected') {
            throw canAdminResponse.reason;
          }
          if (canUploadResponse.status === 'rejected') {
            throw canUploadResponse.reason;
          }
          if (canDownloadResponse.status === 'rejected') {
            throw canDownloadResponse.reason;
          }
          if (canAdminResponse.value.body.can) {
            abilities.push(ABILITIES.ADMIN);
          }
          if (canUploadResponse.value.body.can) {
            abilities.push(ABILITIES.UPLOAD);
          }
          if (canDownloadResponse.value.body.can) {
            abilities.push(ABILITIES.DOWNLOAD);
          }
          if (canDownloadResponse.value.body.pending) {
            abilities.push(ABILITIES.DOWNLOAD_PENDING);
          }
        }
        dataset.abilities = abilities;
        // quality summary info in sep json doc linked to by the describedBy attribute and MUST be of type json
        if (raw.meta && raw.meta.describedBy && raw.meta.describedByType === 'application/json') {
          // quality summary data is now stored in a much smaller file with the same name as 'describedBy' with .quality-summary.json appended to the end
          const summaryFileName = raw.meta.describedBy.replace(
            '.json',
            '.summary.json'
          );
          return DapApiAgent.agent
            .get(summaryFileName)
            .then((response) => {
              const qualityJson = JSON.parse(response.text);
              dataset.quality = new Quality(qualityJson.dataQualitySummary);
              dataset.datasetMetrics = new DatasetMetrics(qualityJson.datasetMetrics);
              return dataset;
            })
            .catch((err: any) => {
              return DapApiAgent.agent
                .get(raw.meta.describedBy)
                .then((response) => {
                  try {
                    const qualityJson = JSON.parse(response.text);
                    // if dataset has a quality metadata json document, load it to parse quality metrics
                    if (qualityJson.dataQualitySummary) {
                      dataset.quality = new Quality(
                        qualityJson.dataQualitySummary
                      );
                    }
                    return dataset;
                  } catch (error) {
                    this.sendNotification(raw.meta.describedBy);
                    return dataset;
                  }
                }).catch(() => {
                  this.sendNotification(raw.meta.describedBy);
                  return dataset;
                });
            });
        }
        return dataset;
      }
    );
  };

  sendNotification = (source: string) => {
    console.error('Failed to retrieve dataset quality characterization data from', source);
    this.notificationService.addNotification(
      NOTIFICATION_ID,
      Status.Error,
      `Failed to retrieve dataset quality characterization JSON file.`,
      `${source}`,
      false,
      false
    );
  };

  private _getStats = (filter: object): Promise<DatasetStats> => {
    return LambdaApiAgent.agent
      .post(`${LAMBDA_API_URL}/searches`)
      .send({
        filter,
        output: 'json',
        source: 'stats',
      })
      .then((response) => {
        let startDate: Moment | null = null;
        let endDate: Moment | null = null;
        const stats = response.body.stats;
        const fileCount = stats.count;
        const byteCount = stats.size;
        if (stats.days != null) {
          for (const dateRangeStr of stats.days) {
            const [startDateStr, endDateStr] = dateRangeStr.split('-');
            const dateFormat = 'YYYYMMDD';
            const newStartDate = moment(startDateStr, dateFormat, true);
            const newEndDate = moment(endDateStr, dateFormat, true);
            if (startDate === null || newStartDate.isBefore(startDate)) {
              startDate = newStartDate;
            }
            if (endDate === null || newEndDate.isAfter(endDate)) {
              endDate = newEndDate;
            }
          }
        }
        return {
          fileCount,
          byteCount,
          startDate,
          endDate,
        };
      });
  };

  private _getRawStats = (filterObj: object): Promise<DatasetRawStats[]> => {
    return LambdaApiAgent.agent
      .post(`${LAMBDA_API_URL}/searches`)
      .send({
        filter: filterObj,
        raw: true,
        output: 'json',
        source: 'stats',
      })
      .then((response) => {
        return map(response.body, (record) => {
          const stats = record.stats.summary;
          return {
            datasetName: record.Dataset,
            extension: record.full_extension,
            updated: moment.unix(record.updated / 1000),
            summary: {
              count: stats.count,
              min: stats.min,
              max: stats.max,
              mean: stats.mean,
              median: stats.median,
              stDev: stats.stdev || 0,
              sum: stats.total,
            },
            timeline: map(
              filter(Object.keys(record.stats.series), (range: string) => {
                // TODO: support range-based summaries somehow
                for (const key of range.split('-')) {
                  try {
                    moment(key, 'YYYYMMDD', true);
                  } catch (err: any) {
                    return false;
                  }
                }
                return true;
              }),
              (range: string) => {
                const [beg, end] = range.split('-');
                const sizeStats = record.stats.series[range].values;
                const countStats = record.stats.series[range].counts;
                return {
                  beg: moment.utc(beg, 'YYYYMMDD', true),
                  end: moment.utc(end, 'YYYYMMDD', true).add(1, 'days'),
                  fileSize: {
                    count: sizeStats.count,
                    min: sizeStats.min,
                    max: sizeStats.max,
                    mean: sizeStats.mean,
                    median: sizeStats.median,
                    stDev: sizeStats.stdev || 0,
                    sum: sizeStats.total,
                  },
                  fileCount: {
                    count: countStats.count,
                    min: countStats.min,
                    max: countStats.max,
                    mean: countStats.mean,
                    median: countStats.median,
                    stDev: countStats.stdev || 0,
                    sum: countStats.total,
                  },
                };
              }
            ),
          };
        }) as DatasetRawStats[];
      });
  };

  private _getOrder = (groupId: string): Promise<FileOrderGroup> => {
    return LambdaApiAgent.agent
      .get(`${LAMBDA_API_URL}/orders/group/${groupId}`)
      .then((response) => {
        const subOrders = response.body.orders.map(
          (order) => new FileOrder(order, this.notificationService, this)
        );
        return new FileOrderGroup(subOrders, this.notificationService);
      });
  };
}
