import React, { useState } from 'react';
import { styled } from '@mui/material/styles';
import { IconButton } from '@mui/material';
import AddIcon from '@extensions/utils/AddIcon';
import { faLock, faThumbsUp } from '@fortawesome/free-solid-svg-icons';
import QualityWarning from '../dataset/QualityWarning';
import StarBorderRoundedIcon from '@mui/icons-material/StarBorderRounded';
import StarRateRoundedIcon from '@mui/icons-material/StarRateRounded';
import CheckIcon from '@mui/icons-material/Check';
import MergeTypeIcon from '@mui/icons-material/MergeType';
import creativeCommonsZeroLogo from '@extensions/resources/cc-zero.png';
import DatasetIconLegend from './DatasetIconLegend';

export interface IDatasetResultIconsProps {
    datasetName: string;
    datasetLevel?: string;
    isDataQualityAffected: boolean;
    isGeneralUseReady: boolean;
    isPublic: boolean;
    isMfaRestricted: boolean;
    classes?: any;
}

const StyledIconGroupSpan = styled('span')(({ theme }) => ({
    '& > *': {
        marginRight: theme.spacing(1),
    },
}));

const StyledRecommendedIconAddIcon = styled(AddIcon)(({ theme }) => ({
    color: theme.palette.success.light,
    verticalAlign: 'middle'
}));

const StyledProprietaryIconAddIcon = styled(AddIcon)(({ theme }) => ({
    verticalAlign: 'middle',
    color: theme.palette.error.dark,
}));

const StyledImg = styled('img')(({
    height: '1rem',
    verticalAlign: 'middle'
}));


const extractLevel = (input: string | undefined): string | null => {
    if(!input) return null;
    const match = input.match(/\(([^)]+)\)/);
    return match ? match[1] : null;
};

const iconStyle = (level: string) => {
    if (level > '0' && level <= '3') {
        return { color: '#51A800', verticalAlign: 'middle' };
    } 
    else if (level >= '4' && level <= '6') {
        return { color: '#51A800', borderBottom: 'solid', verticalAlign: 'middle' }
    } 
    else if (level > '6') {
        return { color: '#51A800', border: 'solid', verticalAlign: 'middle' };
    } 
    else {
        return { color: '#4570E1', verticalAlign: 'middle' };
    }
};

const dataIcon = (dataLevel: string | null, onClick: (dataLevel: string) => void) => {
    if(!dataLevel) return null;
    const category = dataLevel[0];
    const level =  dataLevel[1];

    let IconComponent;

    switch (category) {
        case '0':
            IconComponent = StarBorderRoundedIcon;
            break;
        case 'a':
            IconComponent = StarRateRoundedIcon;
            break;
        case 'b':
            IconComponent = CheckIcon;
            break;
        case 'c':
            IconComponent = MergeTypeIcon;
            break;
        default:
            return null;
    }

    return (
        <IconButton sx={{ padding: '0px', marginRight: '8px' }} onClick={() => onClick(dataLevel)}>
            <IconComponent style={iconStyle(level)} />
        </IconButton>
    );
};

const DatasetResultIcons: React.FC<IDatasetResultIconsProps> = ({
    datasetName,
    datasetLevel,
    isDataQualityAffected,
    isGeneralUseReady,
    isPublic,
    isMfaRestricted,
    ...props
}) => {

    const [dialogOpen, setDialogOpen] = useState<boolean>(false);
    const [selectedIcon, setSelectedIcon] = useState<string | null>(null);

    const handleDialogOpen = (icon: string) => {
        setSelectedIcon(icon)
        setDialogOpen(true);
    }

    const handleDialogClose = () => {
        setSelectedIcon(null);
        setDialogOpen(false);
    }

    const dataLevel = extractLevel(datasetLevel)

    return (
        <>
            <StyledIconGroupSpan>
                {isDataQualityAffected && (
                    <QualityWarning
                        datasetName={datasetName}
                    />
                )}
                {isGeneralUseReady && (
                    <StyledRecommendedIconAddIcon
                        icon={faThumbsUp}
                        title="Data is recommended for general use"
                    />
                )}
                {datasetLevel && (dataIcon(dataLevel, handleDialogOpen))}
                {isPublic && (
                    <StyledImg
                        src={creativeCommonsZeroLogo}
                        title="Data is public domain"
                        alt="CC0 Public Domain Dedication"
                    />
                )}
                {isMfaRestricted && (
                    <StyledProprietaryIconAddIcon
                        icon={faLock}
                        title="Data is [MFA] restricted"
                    />
                )}
            </StyledIconGroupSpan>
            
            <DatasetIconLegend open={dialogOpen} onClose={handleDialogClose} selectedIcon={selectedIcon} />
        </>
    )
};

export default DatasetResultIcons;
