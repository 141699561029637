import React, { useState, useEffect } from 'react';
import { 
    Dialog, 
    DialogTitle, 
    DialogContentText, 
    DialogActions, 
    Tab, 
    Tabs, 
    Box, 
    Grid, 
    Typography, 
    Button, 
    Icon,
    Link,
} from '@mui/material';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import StarBorderRoundedIcon from '@mui/icons-material/StarBorderRounded';
import StarRateRoundedIcon from '@mui/icons-material/StarRateRounded';
import CheckIcon from '@mui/icons-material/Check';
import MergeTypeIcon from '@mui/icons-material/MergeType';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';

interface DatasetIconLegendProps {
    open: boolean;
    onClose: () => void;
    selectedIcon?: string | null;
}

const primaryData = [
    { 
        dataLevel: '00', 
        icon: <StarBorderRoundedIcon style={{color: '#4570E1'}} />, 
        title: "Raw Data (00)", 
        description: "Primary raw data streams collected directly from an instrument"
    },
    { 
        dataLevel: 'a0', 
        icon: <StarRateRoundedIcon style={{color: '#4570E1'}} />, 
        title: "Processed Data (a0)", 
        description: "Raw data converted or processed to standardized data products"},
    { 
        dataLevel: 'b0', 
        icon: <CheckIcon style={{color: '#4570E1'}} />, 
        title: "Reviewed Data (b0)", 
        description: "Intermediate quality-controlled data and metadata that has been reviewed and standardized"
    },
    { 
        dataLevel: 'c0', 
        icon: <MergeTypeIcon style={{color: '#4570E1'}} />, 
        title: "Derived Data (c0)", 
        description: "Data that has been derived or calculated using one or more measured or modeled datasets as input"
    },
]

const consolidatedData = [
    { 
        dataLevel: '01', 
        icon: <StarBorderRoundedIcon style={{color: '#51A800'}} />, 
        title: "Raw Data (0[1-9])", 
        description: "Primary raw data streams collected directly from an instrument that has been consolidated or reformatted"
    },
    { 
        dataLevel: 'a1', 
        icon: <StarRateRoundedIcon style={{color: '#51A800'}} />, 
        title: "Processed Data (a[1-9])", 
        description: "Raw data converted or processed to standardized data products that has been consolidated or reformated"
    },
    { 
        dataLevel: 'b1', 
        icon: <CheckIcon style={{color: '#51A800'}} />, 
        title: "Reviewed Data (b[1-9])", 
        description: "Intermediate quality-controlled data and metadata that has been reviewed and standardized that has been consolidated or reformatted"
    },
    { 
        dataLevel: 'c1', 
        icon: <MergeTypeIcon style={{color: '#51A800'}} />,
        title: "Derived Data (c[1-9])", 
        description: "Data that has been derived or calculated using one or more measured or modeled datasets as input that has been consolidated or reformatted"
    },
]


const DatasetIconLegend: React.FC<DatasetIconLegendProps> = ({ open, onClose, selectedIcon }) => {

    const [selectedTab, setSelectedTab] = useState<number>(0);
    const [selectedRow, setSelectedRow] = useState<number>();

    const handleTabChange = (event: React.SyntheticEvent, tabValue: number) => {
        setSelectedRow(undefined);
        setSelectedTab(tabValue);
    }

    useEffect(() => {
        if (selectedIcon) {
            if(selectedIcon[1] === "0") {
                setSelectedTab(0);
            } else {
                setSelectedTab(1);
            }

            if(selectedIcon[0] === "0") {
                setSelectedRow(0);
            } else if (selectedIcon[0] === "a") {
                setSelectedRow(1);
            } else if(selectedIcon[0] === "b") {
                setSelectedRow(2);
            } else if(selectedIcon[0] === "c") {
                setSelectedRow(3);
            }
        }
    }, [selectedIcon]); 

    const displayedData = selectedTab === 0 ? primaryData : consolidatedData;
    
    return (
        <Dialog sx={{ zIndex: 9999 }} open={open} onClose={onClose}>
            <IconButton
                aria-label="close"
                onClick={onClose}
                sx={(theme) => ({
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: theme.palette.grey[500],
                })}
            >
                <CloseIcon/>
            </IconButton>

            <DialogTitle>
                Data Tiers and Levels
            </DialogTitle>
            <DialogContentText px={3} fontSize={14}>
                Use the legend below to understand the icons of the data tiers and levels. <br />
                Tiers: Designated as 0, a, b, or c. <br />
                Levels: Based on the levels of processing. <br />
                For more information, see our {' '}
                <Link 
                    href="https://a2e.energy.gov/docs/program/Wind_Data_Hub_Data_Standards_V2.1_Aug2024.pdf" 
                    underline='hover'
                    target="_blank"
                    rel="noreferrer noopener"
                > 
                    Wind Data Hub Standards Document <OpenInNewIcon  sx={{ width: 15 }}/> 
                </Link>
            </DialogContentText>
            <Box px={3}>
                <Tabs 
                    textColor='primary'
                    indicatorColor='secondary'
                    value={selectedTab} 
                    onChange={handleTabChange}
                >
                    <Tab label="Primary Data"/>
                    <Tab label="Consolidated Data"/>
                </Tabs>
            </Box>

            <Box px={3} py={3}>
                <Grid container spacing={2} alignItems="center">
                    {displayedData.map((tier, index) => (
                        <Grid
                            key={index}
                            container
                            spacing={2}
                            alignItems="center"
                            sx={{ 
                                backgroundColor: index === selectedRow ? '#e0e0e0' : 'transparent', 
                                padding: '8px', 
                                borderRadius: '2px', 
                                my: '3px', 
                                marginLeft: '10px' 
                            }}
                        >
                            <Grid item>
                                <Icon>{tier.icon}</Icon>
                            </Grid>
                            <Grid item xs={3}>
                                <Typography variant="subtitle2">{tier.title}</Typography>
                            </Grid>
                            <Grid item xs={8}>
                                <Typography variant="body2">{tier.description}</Typography>
                            </Grid>
                        </Grid>
                    ))}
                </Grid>
            </Box>


            <DialogActions>
                <Button 
                    variant="contained"
                    sx={{
                        backgroundColor: 'primary',
                        color: '#FFFFFF',
                        '&:hover': {
                            backgroundColor: 'secondary',
                        },
                        borderRadius: 1,
                        padding: '4px',
                    }}
                    onClick={onClose} 
                    autoFocus
                >
                    OK
                </Button>
            </DialogActions>


        </Dialog>
    )
}

export default DatasetIconLegend
