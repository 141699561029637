import { computed, makeObservable, observable, runInAction } from 'mobx';

import Publication, {
  PublicationType,
  BasePublication,
  JournalArticle,
  TechnicalReport,
  ReferenceDocument,
  PubOrganization,
  PubDataset,
  PubProject,
} from '@extensions/models/Publication';
import { Status, INotificationService } from '@extensions/services//INotificationService';
import DapApiAgent from '@extensions/utils/DapApiAgent';
import { IHistoryService } from '@extensions/services/IHistoryService';
import { ISecurityService } from '@extensions/services/ISecurityService';
import cloneDeep from 'lodash/cloneDeep';

export default class PublicationService {
  private notificationService: INotificationService;
  private historyService: IHistoryService;
  private securityService: ISecurityService;
  sponsorOrgs: PubOrganization[] | null = null;
  datasetOptions: PubDataset[] | null = null;
  projectOptions: PubProject[] | null = null;
  selectedPublicationNotFound: boolean = false;
  selectedPublicationId: number | null = null;
  publicationCache: Record<number, Publication> = {};

  get selectedPublication(): Publication | null {
    if (this.selectedPublicationId === null) {
      return null;
    }
    return this.getCachedPub(this.selectedPublicationId);
  }

  constructor(
    notificationService: INotificationService,
    historyService: IHistoryService,
    securityService: ISecurityService
  ) {
    this.notificationService = notificationService;
    this.historyService = historyService;
    this.securityService = securityService;
    makeObservable(this, {
      selectedPublicationId: observable,
      publicationCache: observable,
      selectedPublication: computed,
      selectedPublicationNotFound: observable,
      sponsorOrgs: observable,
      datasetOptions: observable,
      projectOptions: observable,
    });
  }

  private getErrorDescription = (error: any) => {
    return 'If you believe this is a problem with our website, please contact us.';
  };

  private getErrorMessage = (error: any) => {
    if (typeof error === 'object' && error.message) {
      return `Error: ${error.message}`;
    }
    return 'Whoops! Something went wrong.';
  };

  private setSelectedPublicationId = (id: number | null) => {
    runInAction(() => {
      this.selectedPublicationNotFound = false;
      this.selectedPublicationId = id;
    });
  };

  private getCachedPub = (id: number): Publication | null => {
    const cachedValue = this.publicationCache[id];
    return cachedValue ?? null;
  };

  private pubIsCached = (id: number): boolean => {
    return Boolean(this.getCachedPub(id));
  };

  private cachePub = ({
    id,
    pub,
    force = false,
  }: {
    id: number;
    pub: Publication;
    force?: boolean;
  }): void => {
    if (force || !this.getCachedPub(id)) {
      runInAction(() => (this.publicationCache[id] = pub));
    }
  };

  private removePub = (id: number) => {
    runInAction(() => {
      if (this.selectedPublicationId === id) {
        this.setSelectedPublicationId(id);
      }
      delete this.publicationCache[id];
    });
  };

  private cacheDatasetOptions = (datasetOptions: PubDataset[]): void => {
    runInAction(() => (this.datasetOptions = datasetOptions));
  };

  private cacheProjectOptions = (projectOptions: PubProject[]): void => {
    runInAction(() => (this.projectOptions = projectOptions));
  };

  private cacheSponsorOrgs = (orgs: PubOrganization[]): void => {
    if (!this.sponsorOrgs) {
      runInAction(() => (this.sponsorOrgs = orgs));
    }
  };

  loadDatasetAndProjectOptions = () => {
    const fetchAndCache = async (): Promise<void> => {
      const query = {
        query: {
          match_all: {},
        },
        _source: ['title', 'name', 'project_name', 'project'],
        size: 2000,
      };
      const resp = await DapApiAgent.agent
        .post('/api/datasets/_msearch')
        .send(`{}\n${JSON.stringify(query)}`);

      let datasetOptions = resp.body.responses[0].hits.hits.map((hit) => {
        return {
          title: hit._source.title,
          name: hit._source.name,
          project: hit._source.project_name,
          project_obj: hit._source.project,
        }
      });
      let projectOptions = resp.body.responses[0].hits.hits.map((hit) => {
        return {
          name: hit._source.project.identifier,
          title: hit._source.project.title,
        }
      });

      if (datasetOptions) {
        // Remove duplicates
        datasetOptions = [...new Map(datasetOptions.map(datasetOption => [datasetOption.name, datasetOption])).values()];
        datasetOptions.sort((a, b) => a.project_obj['title'].localeCompare(b.project_obj['title']));
      }
      if (projectOptions) {
        // Remove duplicates
        projectOptions = [...new Map(projectOptions.map(projectOption => [projectOption.name, projectOption])).values()];
        projectOptions.sort((a, b) => a.name.localeCompare(b.name));
      }
      this.cacheDatasetOptions(datasetOptions);
      this.cacheProjectOptions(projectOptions);
    };
    this.notificationService.showStateInUI({
      pending: fetchAndCache(),
      notificationId: 'FETCH_DATASET_OPTIONS',
      errorMessage: this.getErrorMessage,
      errorDescription: this.getErrorDescription,
    });
  };

  loadSponsorOrgs = (): void => {
    if (this.sponsorOrgs) {
      return;
    }
    const fetchAndCache = async (): Promise<void> => {
      const resp = await DapApiAgent.agent.get('/api/refs/sponsors');
      const orgs = resp.body.map(
        (org): PubOrganization => ({
          name: org.name,
        })
      );
      this.cacheSponsorOrgs(orgs);
    };
    this.notificationService.showStateInUI({
      pending: fetchAndCache(),
      notificationId: 'FETCH_SPONSOR_ORGS',
      errorMessage: this.getErrorMessage,
      errorDescription: this.getErrorDescription,
    });
  };

  patchReferenceTypePretty = (pub: Publication) => {
    switch (pub.type) {
      case PublicationType.Platform_Reference:
        pub.type = PublicationType.REFERENCE_DOCUMENT;
        pub.referenceTypePretty = 'Platform Reference'
        break;
      case PublicationType.Platform_Metadata:
        pub.type = PublicationType.REFERENCE_DOCUMENT;
        pub.referenceTypePretty = 'Platform Metadata';
        break;
      case PublicationType.Reference:
        pub.referenceTypePretty = 'Reference';
        pub.refCategory = {name: 'Reference', label: "Project/Dataset Reference"};
        break;
      case PublicationType.Documentation:
        pub.referenceTypePretty = 'Documentation';
        pub.refCategory = {name: 'Documentation', label: "Project/Dataset Documentation"};
        break;
      case PublicationType.Publication:
        pub.referenceTypePretty = 'Publication';
        pub.refCategory = {name: 'Publication', label: "Publication"};
        break;
      case PublicationType.Support_Data:
        pub.referenceTypePretty = 'Support Data';
        pub.refCategory = {name: 'Support Data', label: "Supporting Data"};
        break;
      case PublicationType.Web:
        pub.referenceTypePretty = 'Web';
        pub.refCategory = {name: 'Web', label: "Web Resource"};
        break;
      case PublicationType.Metadata:
        pub.referenceTypePretty = 'Metadata';
        pub.refCategory = {name: 'Metadata', label: "Project/Dataset-provided Metadata"};
        break;
      case PublicationType.Presentation:
        pub.referenceTypePretty = 'Presentation';
        pub.refCategory = {name: 'Presentation', label: "Presentation"};
        break;
      case PublicationType.Standard:
        pub.referenceTypePretty = 'Standard';
        pub.refCategory = {name: 'Standard', label: "Standard or Specification"};
        break;
      case PublicationType.Repository:
        pub.referenceTypePretty = 'Repository';
        pub.refCategory = {name: 'Repository', label: "Repository"};
        break;
      case PublicationType.Vendor_Info:
        pub.referenceTypePretty = 'Vendor Info';
        pub.refCategory = {name: 'Vendor Info', label: "Vendor Information"};
        break;
      case PublicationType.Video:
        pub.referenceTypePretty = 'Video';
        pub.refCategory = {name: 'Video', label: "Video"};
        break;
      default:
        break;
    }
  }

  selectPublication = (id: number): void => {
    const select = async () => {
      this.setSelectedPublicationId(id);
      if (!this.pubIsCached(id)) {
        try {
          const pub = await this.fetchFromDapRESTApi(id);
          this.patchReferenceTypePretty(pub);
          this.cachePub({ id, pub });
        } catch (error: any) {
          if (error.message?.includes('not found')) {
            runInAction(() => (this.selectedPublicationNotFound = true));
          } else {
            throw error;
          }
        }
      }
    };
    this.notificationService.showStateInUI({
      pending: select(),
      notificationId: 'SELECT_PUBLICATION',
      errorMessage: this.getErrorMessage,
      errorDescription: this.getErrorDescription,
    });
  };

  approve = (pub: Publication) => {
    const approveAndUpdateCache = async () => {
      await this.persistApproval(pub);
      const cachedPub = this.getCachedPub(pub.id as number);
      cachedPub!.isApproved = true;
      cachedPub!.isRejected = false;
    };
    this.notificationService.showStateInUI({
      pending: approveAndUpdateCache(),
      notificationId: 'APPROVE_PUBLICATION',
      errorMessage: this.getErrorMessage,
      errorDescription: this.getErrorDescription,
    });
  };

  submit = (pub: Publication, isEdit: boolean) => {
    const submitAndUpdateCache = async () => {
      const clonedPub = cloneDeep(pub);
      const respId = await this.persistSubmission(pub, isEdit);
      this.cachePub({
        id: respId,
        pub: {...clonedPub, id: respId},
        force: true,
      });
      let redirectTo = '/publications';
      if (clonedPub.isApproved || this.securityService.user?.canAdminPubs) {
        redirectTo = `/publication/${respId}`;
      }
      this.historyService.history.replace(redirectTo);
    };
    const makeAnnouncement = () => {
      this.notificationService.addNotification(
        'SUBMIT_PUBLICATION',
        Status.Success,
        'Publication is submitted.',
        '',
        true,
        true
      );
    };
    this.notificationService.showStateInUI({
      pending: submitAndUpdateCache(),
      notificationId: 'SUBMIT_PUBLICATION',
      onSuccess: () => makeAnnouncement(),
      errorMessage: this.getErrorMessage,
      errorDescription: this.getErrorDescription,
    });
  };

  makePending = (pub: Publication) => {
    const makePendingAndUpdateCache = async () => {
      await this.persistMakePending(pub);
      const cachedPub = this.getCachedPub(pub.id as number);
      cachedPub!.isApproved = false;
      cachedPub!.isRejected = false;
    };
    this.notificationService.showStateInUI({
      pending: makePendingAndUpdateCache(),
      notificationId: 'MAKE_PUBLICATION_PENDING',
      errorMessage: this.getErrorMessage,
      errorDescription: this.getErrorDescription,
    });
  };

  reject = (pub: Publication) => {
    const rejectAndUpdateCache = async () => {
      await this.persistRejection(pub);
      const cachedPub = this.getCachedPub(pub.id as number);
      cachedPub!.isApproved = false;
      cachedPub!.isRejected = true;
    };
    this.notificationService.showStateInUI({
      pending: rejectAndUpdateCache(),
      notificationId: 'REJECT_PUBLICATION',
      errorMessage: this.getErrorMessage,
      errorDescription: this.getErrorDescription,
    });
  };

  delete = (pub: Publication) => {
    const rejectAndUpdateCache = async () => {
      await this.persistDeletion(pub);
      this.removePub(pub.id as number);
      this.historyService.history.replace('/publications');
    };
    this.notificationService.showStateInUI({
      pending: rejectAndUpdateCache(),
      notificationId: 'REJECT_PUBLICATION',
      errorMessage: this.getErrorMessage,
      errorDescription: this.getErrorDescription,
    });
  };
  
  private submitJournalArticle = async ({pubInput}: {pubInput: Partial<JournalArticle>}, isEdit: boolean) => {
    this.cleanPubInput(pubInput);
    if (isEdit) {
      await DapApiAgent.agent.put(`/api/refs/${pubInput.id}`).send(pubInput);
      return pubInput.id;
    } else {
      const data = await DapApiAgent.agent.post('/api/refs').send(pubInput);
      return data.body.id;
    }
  };

  private submitTechnicalReport = async ({pubInput}: {pubInput: Partial<TechnicalReport>}, isEdit:boolean) => {
    this.cleanPubInput(pubInput);
    if (isEdit) {
      await DapApiAgent.agent.put(`/api/refs/${pubInput.id}`).send(pubInput);
      return pubInput.id;
    } else {
      const data = await DapApiAgent.agent.post('/api/refs').send(pubInput);
      return data.body.id;
    }
  };

  private submitReferenceDocument = async ({pubInput}: {pubInput: Partial<ReferenceDocument>}, isEdit: boolean) => {
    this.cleanPubInput(pubInput);
    if (pubInput.projects) {
      pubInput.projects = pubInput.projects.map((project) => project.name)
    }
    if (isEdit) {
      if (pubInput.refCategory) {
        delete pubInput.refCategory
      }
      await DapApiAgent.agent.put(`/api/refs/${pubInput.id}`).send(pubInput);
      return pubInput.id;
    } else {
      if (pubInput.refCategory) {
        delete pubInput.refCategory
      }
      const data = await DapApiAgent.agent.post(`/api/refs`).send(pubInput);
      return data.body.id;
    }
  };

  private cleanPubInput(pubInput:any) {
    Object.keys(pubInput).forEach(k => {
      if (!pubInput[k] || pubInput[k] === "") {
        delete pubInput[k]
      }
    });
  }

  private persistSubmission = async (
    pub: Publication,
    isEdit: boolean
  ): Promise<number> => {
    if(pub.refCategory && pub.refCategory.name !== ''){
      switch (pub.refCategory.name) {
        case 'Presentation':
          pub.type = PublicationType.Presentation;
          break;
        case 'Publication':
          pub.type = PublicationType.Publication;
          break;
        case 'Repository':
          pub.type = PublicationType.Repository;
          break;
        case 'Standard':
          pub.type = PublicationType.Standard;
          break;
        case 'Support Data':
          pub.type = PublicationType.Support_Data;
          break;
        case 'Vendor Info':
          pub.type = PublicationType.Vendor_Info;
          break;
        case 'Video':
          pub.type = PublicationType.Video;
          break;
        case 'Web':
          pub.type = PublicationType.Web;
          break;
        case 'Documentation':
          pub.type = PublicationType.Documentation;
          break;
        case 'Reference':
          pub.type = PublicationType.Reference;
          break;
        case 'Metadata':
          pub.type = PublicationType.Metadata;
          break;
        default:
          break;
      }
    }
    const type = pub.type;
    let baseInput: Partial<BasePublication> = {
      title: pub.title,
      type: type,
      abstract: pub.abstract,
      authors: pub.authors,
      doi: pub.doi,
      id: pub.id,
      keywords: pub.keywords,
      primaryContact: pub.primaryContact,
      sponsorOrganizations: pub.sponsorOrganizations,
      datasets: pub.datasets.map((dataset) => {
        if (dataset.title) {
          delete dataset.title
        }
        if (dataset.project_obj) {
          delete dataset.project_obj
        }
        return dataset;
      }),
      projects: pub.projects.map((project) => project.name),
      publicationDate: pub.publicationDate,
    };
    if (this.securityService?.user) {
      baseInput.primaryContact = {
        firstName: this.securityService?.user.firstname,
        lastName: this.securityService?.user.lastname,
        email: this.securityService?.user.email,
      };
    }

    if (baseInput.primaryContact) {
      delete baseInput.primaryContact.username;
    }
    switch (type) {
      case PublicationType.JOURNAL_ARTICLE:
        const journalInput: Partial<JournalArticle> = {
          ...baseInput,
          journalIssue: pub.journalIssue,
          journalName: pub.journalName,
          journalVolume: pub.journalVolume,
        };
        return await this.submitJournalArticle({ pubInput: journalInput }, isEdit);
      case PublicationType.TECHNICAL_REPORT:
        const reportInput: Partial<TechnicalReport> = {
          ...baseInput,
          url: pub.url,
          reportNumber: pub.reportNumber,
        };
        return await this.submitTechnicalReport({ pubInput: reportInput }, isEdit);
      default:
        const refDocInput: Partial<ReferenceDocument> = {
          id: pub.id,
          datasets: pub.datasets,
          projects: pub.projects,
          title: pub.title,
          type: pub.type,
          refCategory: pub.refCategory,
          url: (pub.url === "" || !pub.url) ? pub.uploadedFileUrl : pub.url,
        };
        return await this.submitReferenceDocument({ pubInput: refDocInput }, isEdit);
    }
  };

  private persistApproval = async (pub: Publication): Promise<void> => {
    if (!pub.id) {
      throw new Error('Can not move to pending without id');
    }
    await DapApiAgent.agent.put(`/api/refs/${pub.id}/approve`);
  };

  persistDeletion = async (pub: Publication): Promise<void> => {
    if (!pub.id) {
      throw new Error('Can not move to pending without id');
    }
    await DapApiAgent.agent.delete(`/api/refs/${pub.id}`);
  };

  persistMakePending = async (pub: Publication): Promise<void> => {
    if (!pub.id) {
      throw new Error('Can not move to pending without id');
    }
    await DapApiAgent.agent.put(`/api/refs/${pub.id}/reset`);
  };

  persistRejection = async (pub: Publication): Promise<void> => {
    if (!pub.id) {
      throw new Error('Can not reject without id');
    }
    await DapApiAgent.agent.put(`/api/refs/${pub.id}/reject`);
  };

  fetchFromOsti = async (doi: string): Promise<Publication> => {
    const data = await DapApiAgent.agent.get(`/api/refs/find/${doi}`);
    const cleanedPub = {
      ...data.body,
      datasets:[]
    };
    delete cleanedPub.id;
    return cleanedPub;
  };

  fetchFromDapRESTApi = async (id: number) => {
    const resp = await DapApiAgent.agent.get(`/api/refs/${id}`);
    return resp.body;
  }

  throwIfErrors = (errors) => {
    if (errors) {
      let message = 'Could not fetch publication';
      if (errors.length > 0 && errors[0].message) {
        message = errors[0].message;
        if (message === 'validation') {
          const messagesByField = errors[0].extensions.validation;
          message = Object.values(messagesByField)[0] as string;
        }
      }
      throw new Error(message);
    }
  };

  prettyPublicationType = (publicationType: PublicationType): string => {
    const typeToPrettyString: Record<Exclude<
      PublicationType,
      PublicationType.Documentation
      | PublicationType.Metadata
      | PublicationType.Platform_Metadata
      | PublicationType.PlatformMetadata
      | PublicationType.Platform_Reference
      | PublicationType.PlatformReference
      | PublicationType.Reference
      | PublicationType.Publication
      | PublicationType.Support_Data
      | PublicationType.Web
      | PublicationType.Presentation
      | PublicationType.Standard
      | PublicationType.Repository
      | PublicationType.Vendor_Info
      | PublicationType.Video
    >, string> = {
      [PublicationType.JOURNAL_ARTICLE]: 'Journal Article',
      [PublicationType.TECHNICAL_REPORT]: 'Technical Report',
      [PublicationType.REFERENCE_DOCUMENT]: 'Reference Document',
    };
    return typeToPrettyString[publicationType];
  };
}
